<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.004 512.004"
    style="enable-background:new 0 0 512.004 512.004;"
    xml:space="preserve"
  >
    <rect
      x="170.465"
      y="66.298"
      style="fill:#FED159;"
      width="171.061"
      height="128.306"
    />
    <rect y="138.563" style="fill:#82DCC7;" width="512.004" height="307.143" />
    <circle style="fill:#FFFFFF;" cx="134.449" cy="295.628" r="93.299" />
    <path
      style="fill:#F64B4A;"
      d="M182.876,364.07v11.314c-14.114,8.593-30.703,13.543-48.432,13.543
	c-17.729,0-34.306-4.951-48.419-13.53V364.07c0-26.763,21.669-48.432,48.419-48.432C161.193,315.638,182.876,337.307,182.876,364.07
	z"
    />
    <g>
      <path
        style="fill:#65B4BB;"
        d="M134.443,398.647c-56.804,0-103.019-46.215-103.019-103.019
		c0-56.806,46.215-103.019,103.019-103.019s103.019,46.215,103.019,103.019S191.248,398.647,134.443,398.647z M134.443,212.049
		c-46.086,0-83.579,37.494-83.579,83.579s37.494,83.579,83.579,83.579s83.579-37.494,83.579-83.579
		C218.022,249.541,180.528,212.049,134.443,212.049z"
      />
      <rect
        x="416.138"
        y="192.607"
        style="fill:#65B4BB;"
        width="47.302"
        height="19.44"
      />
    </g>
    <path
      style="fill:#FCD589;"
      d="M178.255,291.152c0,24.197-19.622,43.805-43.818,43.805c-24.184,0-43.805-19.609-43.805-43.805
	c0-2.514,0.207-4.964,0.622-7.361c1.231-7.31,4.29-14.01,8.67-19.622c1.97-2.514,4.212-4.821,6.687-6.843
	c7.569-6.234,17.263-9.979,27.825-9.979c10.576,0,20.27,3.745,27.838,9.966c2.462,2.035,4.705,4.342,6.687,6.856
	c4.381,5.612,7.439,12.312,8.67,19.622C178.048,286.189,178.255,288.638,178.255,291.152z"
    />
    <g>
      <rect
        x="296.269"
        y="192.607"
        style="fill:#65B4BB;"
        width="93.961"
        height="19.44"
      />
      <rect
        x="370.79"
        y="280.14"
        style="fill:#65B4BB;"
        width="92.665"
        height="19.44"
      />
      <rect
        x="296.269"
        y="280.14"
        style="fill:#65B4BB;"
        width="48.598"
        height="19.44"
      />
      <rect
        x="296.269"
        y="379.208"
        style="fill:#65B4BB;"
        width="167.173"
        height="19.44"
      />
      <rect
        x="296.269"
        y="236.374"
        style="fill:#65B4BB;"
        width="167.173"
        height="19.44"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
